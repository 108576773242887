import React, { useState, useEffect } from "react";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  Snackbar,
  Alert,
  Grid,
  Chip,
} from "@mui/material";
import baseUrl from "./Configs";

const AcademicSessions = () => {
  const [academicSessions, setAcademicSessions] = useState([]);
  const [currentSession, setCurrentSession] = useState(null);
  const [newSessionName, setNewSessionName] = useState("");
  const [startDate, setStartDate] = useState(null); // Add this
  const [endDate, setEndDate] = useState(null); // Add this
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const getAllacademicSessionsUrl = `${baseUrl}/academicsessionroute/getallacademicsessions`;
  const getCurrentAcademicSessionUrl = `${baseUrl}/academicsessionroute/current`;
  const createAcademicSessionUrl = `${baseUrl}/academicsessionroute/createacademicsession`;

  useEffect(() => {
    fetchAcademicSessions();
    fetchCurrentSession();
  }, []);

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  // let optionsForPost = {
  //   method: "POST",
  //   mode: "cors",
  //   headers: {
  //     "Content-Type": "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //     Authorization: "Bearer " + localStorage.getItem("token"),
  //   },
  //   body: JSON.stringify(academicSessionData),
  // };

  const fetchAcademicSessions = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        getAllacademicSessionsUrl,
        optionsForGet
      );
      setAcademicSessions(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar("Failed to fetch academic sessions", "error");
    }
  };

  const fetchCurrentSession = async () => {
    try {
      const response = await axios.get(
        getCurrentAcademicSessionUrl,
        optionsForGet
      );
      setCurrentSession(response.data);
    } catch (error) {
      showSnackbar("Failed to fetch current academic session", "error");
    }
  };

  const handleCreateSession = async () => {
    if (!newSessionName) {
      showSnackbar("Session name is required", "warning");
      return;
    }

    if (!startDate || !endDate) {
      showSnackbar("Both start and end dates are required", "warning");
      return;
    }

    const newSessionData = {
      academicSessionName: newSessionName,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      isActive: "false",
    };

    const optionsForPost = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: newSessionData,
      url: createAcademicSessionUrl,
    };

    setLoading(true);
    try {
      await axios(optionsForPost);
      setNewSessionName("");
      setStartDate(null);
      setEndDate(null);
      fetchAcademicSessions();
      showSnackbar("Academic session created successfully", "success");
    } catch (error) {
      showSnackbar("Failed to create academic session", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleActivateSession = async (id) => {
    setLoading(true);
    try {
      await axios.put(
        `${baseUrl}/academicsessionroute/activate/${id}`,
        {}, // PUT requests often require an empty body if no data is sent
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      fetchCurrentSession();
      fetchAcademicSessions();
      showSnackbar("Academic session activated successfully", "success");
    } catch (error) {
      showSnackbar("Failed to activate academic session", "error");
    } finally {
      setLoading(false);
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const closeSnackbar = () => {
    setSnackbar({ open: false, message: "", severity: "success" });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ padding: 4 }}>
        <Typography variant="h6" gutterBottom>
          Current Academic Session
        </Typography>
        {currentSession ? (
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: 2,
              padding: 2,
              marginBottom: 2,
            }}
          >
            <Typography variant="body1">
              <strong>Name:</strong> {currentSession.academicSessionName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>Start Date:</strong>{" "}
              {new Date(currentSession.startDate).toLocaleDateString()}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>End Date:</strong>{" "}
              {new Date(currentSession.endDate).toLocaleDateString()}
            </Typography>
            <Chip
              label="Active"
              color="success"
              size="small"
              sx={{ marginTop: 1 }}
            />
          </Box>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No active academic session.
          </Typography>
        )}

        <Divider sx={{ marginY: 2 }} />

        <Typography variant="h6" gutterBottom>
          All Academic Sessions
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <List>
            <Grid container spacing={2}>
              {academicSessions.map((session) => (
                <Grid
                  item
                  xs={12} // Full width on mobile devices
                  sm={6} // Half width on tablets and larger screens
                  key={session._id}
                >
                  <Box
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: 2,
                      padding: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="body1">
                      <strong>Name:</strong> {session.academicSessionName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>Start Date:</strong>{" "}
                      {new Date(session.startDate).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>End Date:</strong>{" "}
                      {new Date(session.endDate).toLocaleDateString()}
                    </Typography>
                    {session.isActive ? (
                      <Chip
                        label="Active"
                        color="success"
                        size="small"
                        sx={{ marginTop: 1 }}
                      />
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleActivateSession(session._id)}
                        sx={{ marginTop: 1 }}
                      >
                        Activate
                      </Button>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </List>
        )}

        <Divider sx={{ marginY: 2 }} />

        <Typography variant="h6" gutterBottom>
          Create New Academic Session
        </Typography>
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: 2, marginY: 2 }}
        >
          <TextField
            label="Session Name"
            value={newSessionName}
            onChange={(e) => setNewSessionName(e.target.value)}
            fullWidth
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="success"
            onClick={handleCreateSession}
            disabled={loading}
          >
            Create
          </Button>
        </Box>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={closeSnackbar}
        >
          <Alert
            onClose={closeSnackbar}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
};

export default AcademicSessions;
