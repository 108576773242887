import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Grid,
} from "@mui/material";
import baseUrl from "./Configs";
import Header from "./Header";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  teacherSelectedSectionState,
  teacherSelectedClassState,
  selectedClassIdState,
} from "./Atoms";

const MonthlyFeeStatus = ({
  selectedClass,
  selectedSection,
  monthIndex,
  selectedClassId,
}) => {
  const [feeStats, setFeeStats] = useState({
    totalFeePaid: 0,
    totalFeeRemaining: 0,
  });

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const fetchFeeStats = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/feeroute/getfeestatusofclass?classId=${selectedClassId}&monthIndex=${monthIndex}`,
        optionsForGet
      );
      const data = await response.json();
      setFeeStats(data);
    } catch (error) {
      console.error("Error fetching fee statistics:", error);
    }
  };

  useEffect(() => {
    if (selectedClassId) {
      fetchFeeStats();
    }
  }, [selectedClassId, monthIndex]);

  return (
    <Box mb={4}>
      <Typography variant="h6">
        Class Fee Statistics for Class {selectedClass} - Section{" "}
        {selectedSection} (Month: {monthIndex + 1})
      </Typography>
      <Typography>Total Fee Paid: ₹{feeStats.totalFeePaid}</Typography>
      <Typography>
        Total Fee Remaining: ₹{feeStats.totalFeeRemaining}
      </Typography>
    </Box>
  );
};

const CostManagement = () => {
  const [feeType, setFeeType] = useState("");
  const [amount, setAmount] = useState("");
  const [monthIndex, setMonthIndex] = useState(new Date().getMonth()); // Default to current month
  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const selectedClassId = useRecoilValue(selectedClassIdState);

  const setFeeForClassUrl = `${baseUrl}/feeroute/setfeeforclass`;

  const handleSetFee = async () => {
    try {
      const response = await fetch(setFeeForClassUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          className: selectedClass,
          section: selectedSection,
          feeData: {
            type: feeType,
            amount: amount,
          },
        }),
      });

      const data = await response.json();
      console.log("Fee set response:", data);
    } catch (error) {
      console.error("Error setting fee:", error);
    }
  };

  return (
    <div>
      <Header />
      <Paper elevation={3} style={{ padding: "20px", margin: "20px" }}>
        <Typography variant="h5" gutterBottom>
          Cost Management
        </Typography>

        {/* Month Selector */}
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel id="month-select-label">Select Month</InputLabel>
            <Select
              labelId="month-select-label"
              value={monthIndex}
              onChange={(e) => setMonthIndex(e.target.value)}
            >
              <MenuItem value={0}>January</MenuItem>
              <MenuItem value={1}>February</MenuItem>
              <MenuItem value={2}>March</MenuItem>
              <MenuItem value={3}>April</MenuItem>
              <MenuItem value={4}>May</MenuItem>
              <MenuItem value={5}>June</MenuItem>
              <MenuItem value={6}>July</MenuItem>
              <MenuItem value={7}>August</MenuItem>
              <MenuItem value={8}>September</MenuItem>
              <MenuItem value={9}>October</MenuItem>
              <MenuItem value={10}>November</MenuItem>
              <MenuItem value={11}>December</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Fee Statistics */}
        <MonthlyFeeStatus
          selectedClass={selectedClass}
          selectedSection={selectedSection}
          monthIndex={monthIndex}
          selectedClassId={selectedClassId}
        />

        {/* Set Fee Form */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Set Fee for Class {selectedClass} - Section {selectedSection}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="fee-type-label">Fee Type</InputLabel>
                <Select
                  labelId="fee-type-label"
                  value={feeType}
                  onChange={(e) => setFeeType(e.target.value)}
                >
                  <MenuItem value="admissionFee">Admission Fee</MenuItem>
                  <MenuItem value="tuitionFeePerMonth">Tuition Fee</MenuItem>
                  <MenuItem value="transportFeePerMonth">
                    Transport Fee
                  </MenuItem>
                  <MenuItem value="examFee">Exam Fee</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Amount (₹)"
                type="number"
                fullWidth
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSetFee}
                disabled={!selectedClass || !selectedSection}
              >
                Set Fee
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
};

export default CostManagement;
