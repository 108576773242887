import React, { useState } from "react";
import baseUrl from "./Configs";

import {
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box,
  Grid,
  Typography,
} from "@mui/material";

const AddNewStaff = () => {
  const [staffName, setStaffName] = useState("");
  const [staffId, setStaffId] = useState("");
  const [staffEmail, setStaffEmail] = useState("");
  const [staffPhoneNumber, setStaffPhoneNumber] = useState("");
  const [staffAddress, setStaffAddress] = useState("");
  const [staffGender, setStaffGender] = useState("");
  const [staffDateOfBirth, setStaffDateOfBirth] = useState("");
  const [staffRole, setStaffRole] = useState("");
  const [password, setPassword] = useState("");

  const [educationDetails, setEducationDetails] = useState([
    { institution: "", qualification: "", yearOfPassing: "" },
  ]);
  const [bankAccountDetails, setBankAccountDetails] = useState({
    accountNumber: "",
    bankName: "",
    ifscCode: "",
    upiId: "",
  });

  const addNewStaffUrl = `${baseUrl}/staffroute/addnewstaff`;

  // Function to handle the submission of the form
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Preparing the data to be sent in the request
    const staffInfo = {
      name: staffName,
      staffId: staffId,
      email: staffEmail,
      phoneNumber: staffPhoneNumber,
      address: staffAddress,
      gender: staffGender,
      dateOfBirth: staffDateOfBirth,
      role: staffRole,
      username: staffEmail,
      password: password,
      educationDetails: educationDetails,
      bankAccountDetails: bankAccountDetails,
    };

    const options = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(staffInfo),
    };

    // Sending the request to the server
    try {
      const response = await fetch(addNewStaffUrl, options);
      const data = await response.json();
      console.log(data); // You can replace this with an alert or any other method to show the result to the user
    } catch (error) {
      console.error("Error adding new staff:", error);
    }
  };

  const addEducationDetail = () => {
    setEducationDetails([
      ...educationDetails,
      { institution: "", qualification: "", yearOfPassing: "" },
    ]);
  };

  const handleEducationChange = (index, event) => {
    const values = [...educationDetails];
    values[index][event.target.name] = event.target.value;
    setEducationDetails(values);
  };

  const handleBankAccountChange = (event) => {
    setBankAccountDetails({
      ...bankAccountDetails,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              label="Name"
              value={staffName}
              onChange={(e) => setStaffName(e.target.value)}
              required
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="gender-label">Gender</InputLabel>
            <Select
              labelId="gender-label"
              value={staffGender}
              onChange={(e) => setStaffGender(e.target.value)}
              required
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Date of Birth"
              type="date"
              value={staffDateOfBirth}
              onChange={(e) => setStaffDateOfBirth(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Staff ID"
              value={staffId}
              onChange={(e) => setStaffId(e.target.value)}
              required
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Email"
              value={staffEmail}
              onChange={(e) => setStaffEmail(e.target.value)}
              required
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Phone Number"
              value={staffPhoneNumber}
              onChange={(e) => setStaffPhoneNumber(e.target.value)}
              required
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Address"
              value={staffAddress}
              onChange={(e) => setStaffAddress(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              value={staffRole}
              onChange={(e) => setStaffRole(e.target.value)}
              required
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="teacher">Teacher</MenuItem>
              <MenuItem value="clerk">Clerk</MenuItem>
              <MenuItem value="librarian">Librarian</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Account Number"
              name="accountNumber"
              value={bankAccountDetails.accountNumber}
              onChange={handleBankAccountChange}
              required
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Bank Name"
              name="bankName"
              value={bankAccountDetails.bankName}
              onChange={handleBankAccountChange}
              required
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="IFSC Code"
              name="ifscCode"
              value={bankAccountDetails.ifscCode}
              onChange={handleBankAccountChange}
              required
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="UPI ID"
              name="upiId"
              value={bankAccountDetails.upiId}
              onChange={handleBankAccountChange}
              required
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Typography variant="h6">Education Details</Typography>
        {educationDetails.map((educationDetail, index) => (
          <div key={index}>
            <FormControl fullWidth>
              <TextField
                label="Institution"
                name="institution"
                value={educationDetail.institution}
                onChange={(event) => handleEducationChange(index, event)}
                required
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Qualification"
                name="qualification"
                value={educationDetail.qualification}
                onChange={(event) => handleEducationChange(index, event)}
                required
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Year of Passing"
                name="yearOfPassing"
                value={educationDetail.yearOfPassing}
                onChange={(event) => handleEducationChange(index, event)}
                required
              />
            </FormControl>
          </div>
        ))}
        <Button onClick={addEducationDetail}>
          Add Another Education Detail
        </Button>
      </Box>

      <FormControl fullWidth>
        <Button type="submit" variant="contained" color="primary">
          Add Staff
        </Button>
      </FormControl>
    </form>
  );
};

export default AddNewStaff;
