import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import baseUrl from "./Configs";
import Header from "./Header";
import { useRecoilValue } from "recoil";
import {
  teacherSelectedClassState,
  teacherSelectedSubjectState,
  teacherSelectedSectionState,
} from "./Atoms";

const Topic = ({
  topic,
  onChange,
  onAddSubtopic,
  onDeleteTopic,
  path = [],
  level = 0,
}) => (
  <Card style={{ marginLeft: `${level * 20}px`, marginTop: "10px" }}>
    <CardContent>
      <Typography variant="h6">
        {level === 0 ? "Main Topic" : "Subtopic"}
      </Typography>
      <TextField
        label="Topic Title"
        name="title"
        value={topic.title}
        onChange={(e) => onChange(e, [...path, "title"])}
      />
      <TextField
        label="Topic Description"
        name="description"
        value={topic.description}
        onChange={(e) => onChange(e, [...path, "description"])}
      />
      {topic.subtopics.map((subtopic, index) => (
        <Topic
          key={index}
          topic={subtopic}
          onChange={onChange}
          onAddSubtopic={onAddSubtopic}
          onDeleteTopic={onDeleteTopic} // pass onDeleteTopic prop here
          path={[...path, "subtopics", index]}
          level={level + 1}
        />
      ))}
      <Button onClick={() => onAddSubtopic([...path, "subtopics"])}>
        Add Subtopic
      </Button>
      <Button onClick={() => onDeleteTopic(path)}>Delete Topic</Button>
    </CardContent>
  </Card>
);

const AddNewSyllabus = () => {
  const teacherSelectedClass = useRecoilValue(teacherSelectedClassState);
  const teacherSelectedSection = useRecoilValue(teacherSelectedSectionState);
  const teacherSelectedSubject = useRecoilValue(teacherSelectedSubjectState);
  const [isSyllabusFound, setIsSyllabusFound] = useState(false); // Track if syllabus is found

  const [openDialog, setOpenDialog] = useState(false); // Manage dialog visibility
  const [dialogMessage, setDialogMessage] = useState(""); // Dialog message

  let editSyllabusUrl = `${baseUrl}/syllabusroute/updatesyllabus`;

  // Initial empty syllabus state
  const [syllabus, setSyllabus] = useState({
    className: teacherSelectedClass,
    section: teacherSelectedSection,
    subject: teacherSelectedSubject,
    syllabus: {
      topics: [
        {
          title: "",
          description: "",
          subtopics: [],
        },
      ],
    },
  });

  const handleInputChange = (event, path) => {
    const { name, value } = event.target;
    const newSyllabus = { ...syllabus };
    if (path.length === 1) {
      newSyllabus[path[0]] = value;
    } else {
      let currentLevel = newSyllabus;
      for (let i = 0; i < path.length - 1; i++) {
        currentLevel = currentLevel[path[i]];
      }
      currentLevel[path[path.length - 1]] = value;
    }
    setSyllabus(newSyllabus);
  };

  const handleAddTopic = () => {
    const newSyllabus = { ...syllabus };
    newSyllabus.syllabus.topics.push({
      title: "",
      description: "",
      subtopics: [],
    });
    setSyllabus(newSyllabus);
  };

  const handleDeleteTopic = (path) => {
    const newSyllabus = { ...syllabus };
    let currentLevel = newSyllabus;
    for (let i = 0; i < path.length - 2; i++) {
      currentLevel = currentLevel[path[i]];
    }
    currentLevel[path[path.length - 2]].splice(path[path.length - 1], 1);
    setSyllabus(newSyllabus);
  };

  const handleAddSubtopic = (path) => {
    const newSyllabus = { ...syllabus };
    let currentLevel = newSyllabus;
    for (let i = 0; i < path.length - 1; i++) {
      currentLevel = currentLevel[path[i]];
    }
    currentLevel[path[path.length - 1]].push({
      title: "",
      description: "",
      subtopics: [],
    });
    setSyllabus(newSyllabus);
  };

  const handleSubmit = async () => {
    if (
      !teacherSelectedClass ||
      !teacherSelectedSection ||
      !teacherSelectedSubject
    ) {
      setDialogMessage(
        "Please select class, section, and subject before submitting the syllabus."
      );
      setOpenDialog(true);
      return;
    }

    try {
      let response;
      if (isSyllabusFound) {
        response = await axios.put(editSyllabusUrl, syllabus, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
      } else {
        response = await axios.post(
          `${baseUrl}/syllabusroute/addnewsyllabus`,
          syllabus,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseDialog = () => setOpenDialog(false);

  useEffect(() => {
    if (
      teacherSelectedClass &&
      teacherSelectedSection &&
      teacherSelectedSubject
    ) {
      const getSyllabusUrl = `${baseUrl}/syllabusroute/getsyllabus?className=${teacherSelectedClass}&subject=${teacherSelectedSubject}&section=${teacherSelectedSection}`;

      const fetchSyllabus = async () => {
        try {
          const response = await axios.get(getSyllabusUrl, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          if (response.data) {
            setIsSyllabusFound(true); // Syllabus exists
            setSyllabus({
              className: teacherSelectedClass,
              section: teacherSelectedSection,
              subject: teacherSelectedSubject,
              syllabus: response.data, // Load the existing syllabus
            });
          } else {
            setIsSyllabusFound(false); // No syllabus found
            setSyllabus({
              className: teacherSelectedClass,
              section: teacherSelectedSection,
              subject: teacherSelectedSubject,
              syllabus: {
                topics: [
                  {
                    title: "",
                    description: "",
                    subtopics: [],
                  },
                ],
              },
            });
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchSyllabus();
    }
  }, [teacherSelectedClass, teacherSelectedSection, teacherSelectedSubject]);

  useEffect(() => {
    setSyllabus((prevSyllabus) => ({
      ...prevSyllabus,
      className: teacherSelectedClass,
      section: teacherSelectedSection,
      subject: teacherSelectedSubject,
    }));
  }, [teacherSelectedClass, teacherSelectedSection, teacherSelectedSubject]);

  return (
    <div>
      <Header />

      {syllabus.syllabus &&
        syllabus.syllabus.topics.length > 0 &&
        syllabus.syllabus.topics.map((topic, index) => (
          <Topic
            key={index}
            topic={topic}
            onChange={handleInputChange}
            onAddSubtopic={handleAddSubtopic}
            onDeleteTopic={handleDeleteTopic}
            path={["syllabus", "topics", index]}
            level={0}
          />
        ))}

      <Button onClick={handleAddTopic} style={{ marginTop: "10px" }}>
        Add Main Topic
      </Button>
      <Button
        variant="contained"
        fullWidth
        color="primary"
        onClick={handleSubmit}
        style={{ marginTop: "10px" }}
      >
        {isSyllabusFound ? "Update Syllabus" : "Add Syllabus"}
      </Button>

      {/* Dialog for missing class, section, or subject */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Incomplete Information</DialogTitle>
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddNewSyllabus;
