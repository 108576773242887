import React, { useEffect, useState } from "react";
import baseUrl from "../Configs";
import BounceLoader from "react-spinners/BounceLoader";

import { useRecoilValue, useRecoilState } from "recoil";
import {
  receivedStudentListState,
  teacherSelectedClassState,
  teacherSelectedSectionState,
  teacherSelectedDateState,
} from "../Atoms";

import "./Css/AddAttendance.css";

const AddAttendance = ({ onSubmit }) => {
  const [attendance, setAttendance] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [receivedStudentList, setReceivedStudentListState] = useRecoilState(
    receivedStudentListState
  );
  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const date = useRecoilValue(teacherSelectedDateState);

  const toggleAttendance = (rollNumber, status) => {
    setAttendance((prev) => ({
      ...prev,
      [rollNumber]: status,
    }));
  };

  const fetchStudents = async () => {
    setIsLoading(true);
    const getStudentsUrl = `${baseUrl}/classroute/getstudentlist?className=${selectedClass}&section=${selectedSection}`;
    const response = await fetch(getStudentsUrl, optionsForGet);
    const data = await response.json();
    setReceivedStudentListState(data);
    setIsLoading(false);
  };

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const submitAttendance = async () => {
    setIsLoading(true);
    const selectedDate = date
      ? new Date(date).toISOString().split("T")[0] // Use teacher-selected date
      : new Date().toISOString().split("T")[0]; // Use current date if not selected

    const url = `${baseUrl}/studentroute/updateattendance`;
    const data = {
      className: selectedClass,
      section: selectedSection,
      date: selectedDate, // Use the determined date
      attendance,
    };

    const postOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(url, postOptions);
      const responseData = await response.text();
      console.log(responseData);
      setIsLoading(false); // Set loading to false when fetch is done
      onSubmit();
    } catch (error) {
      console.error(error);
      setIsLoading(false); // Also set loading to false on error
    }
  };

  useEffect(() => {
    // if (receivedStudentList.length === 0) {
    fetchStudents();
    // }
  }, [selectedClass, selectedSection]);

  // if (isLoading) {
  //   return (
  //     <div className="loader">
  //       <BounceLoader color="#4A90E2" loading={isLoading} size={60} />
  //     </div>
  //   );
  // }

  return (
    <div className="add-attendance">
      {isLoading && (
        <div className="loader">
          <BounceLoader color="#4A90E2" loading={isLoading} size={60} />
        </div>
      )}
      {receivedStudentList.map((student) => (
        <div key={student.rollNumber} className="pill">
          <div className="row student-info">
            <span>
              {student.rollNumber} - {student.name}
            </span>
          </div>

          <div className="row attendance-status">
            <div
              className="half"
              onClick={() => toggleAttendance(student.rollNumber, "P")}
              style={{
                backgroundColor:
                  attendance[student.rollNumber] === "P" ? "green" : "white",
                color:
                  attendance[student.rollNumber] === "P" ? "white" : "black",
              }}
            >
              <span>Present</span>
            </div>
            <div
              className="half"
              onClick={() => toggleAttendance(student.rollNumber, "A")}
              style={{
                backgroundColor:
                  attendance[student.rollNumber] === "A" ? "red" : "white",
                color:
                  attendance[student.rollNumber] === "A" ? "white" : "black",
              }}
            >
              Absent
            </div>
          </div>
        </div>
      ))}
      <button onClick={submitAttendance}>Submit</button>
    </div>
  );
};

export default AddAttendance;
