import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import styles for the toast

import baseUrl from "./Configs";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  link: {
    marginTop: "1rem",
  },
});

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const classes = useStyles();
  const navigate = useNavigate();

  const loginUrl = `${baseUrl}/auth/login`;

  // Check for token in local storage and verify
  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          console.log("Verifying token");
          const response = await fetch(`${baseUrl}/auth/verify`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.ok) {
            navigate("/super");
          } else {
            console.log("Token is invalid");
            localStorage.removeItem("token");
            toast.error("Session expired. Please log in again.");
          }
        } catch (error) {
          console.error("Error verifying token:", error);
          localStorage.removeItem("token"); // Clear token in case of errors
        }
      }
    };

    verifyToken();
  }, [navigate]);

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.token);
        navigate("/super"); // Redirect to 'super' page
      } else {
        const errorData = await response.json(); // Parse error response
        console.error("Login failed:", errorData.message);
        toast.error("Failed to login: " + errorData.message); // Show toast with error message
      }
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error. Please check internet/try again later.");
    }
  };

  return (
    <div className="login-form">
      <ToastContainer /> {/* Add ToastContainer here */}
      <Typography variant="h4">Login</Typography>
      <form className={classes.form} onSubmit={handleLogin}>
        <TextField
          label="Email/Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" color="primary" type="submit">
          Login
        </Button>
        <Link href="/forgot-password" className={classes.link}>
          Forgot password?
        </Link>
        <Link href="/register" className={classes.link}>
          Don't have an account? Register
        </Link>
      </form>
    </div>
  );
};

export default LoginForm;
