const localhost = "http://192.168.31.160";
const port = "3000";

const cloud_url = "https://data.jyanganj.com";

const baseUrl = `${cloud_url}/v1`;

// const baseUrl = `${localhost}:${port}/v1`;

// Export the base URL
export default baseUrl;
