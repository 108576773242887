import React, { useState, useEffect } from "react";
import baseUrl from "./Configs";

import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import "./Css/Basics.css";

const EditExistingClass = (props) => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [subjects, setSubjects] = useState([
    { subjectName: "", action: "", teacherId: "" },
  ]);
  const [teachersList, setTeachersList] = useState([]);
  const [errors, setErrors] = useState({}); // For validation errors

  const editClassUrl = `${baseUrl}/classroute/editclass`;
  const getClassesUrl = `${baseUrl}/classroute/getavailableclasses`;
  const getAllTeachersUrl = `${baseUrl}/staffroute/getallteachers`;

  // Fetch available classes
  const getAvailableClasses = async () => {
    try {
      const response = await fetch(getClassesUrl, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const data = await response.json();
      setClasses(groupClasses(data));
    } catch (error) {
      console.error("Failed to fetch classes:", error);
      alert("Failed to fetch classes. Please try again.");
    }
  };

  // Fetch all teachers
  const getAllTeachers = async () => {
    try {
      const response = await fetch(getAllTeachersUrl, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const data = await response.json();
      setTeachersList(data);
    } catch (error) {
      console.error("Failed to fetch teachers:", error);
      alert("Failed to fetch teachers. Please try again.");
    }
  };

  // Group classes by className and section
  const groupClasses = (classesData) => {
    return classesData.reduce((groupedClasses, cls) => {
      // Find the class in the grouped classes
      const classIndex = groupedClasses.findIndex(
        (groupedClass) => groupedClass.className === cls.className
      );

      // If the class is not yet in the grouped classes, add it
      if (classIndex === -1) {
        groupedClasses.push({
          className: cls.className,
          sectionsAndSubjects: [
            { section: cls.section, subjects: cls.subjects },
          ],
        });
      } else {
        // Otherwise, add the section and subjects to the class in the grouped classes
        groupedClasses[classIndex].sectionsAndSubjects.push({
          section: cls.section,
          subjects: cls.subjects,
        });
      }
      return groupedClasses;
    }, []);
  };

  useEffect(() => {
    getAvailableClasses();
    getAllTeachers();
  }, []);

  // Handle class selection
  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    setSelectedSection(""); // Reset section when class changes
    setSubjects([{ subjectName: "", action: "", teacherId: "" }]); // Reset subjects
  };

  // Handle section selection
  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
    setSubjects([{ subjectName: "", action: "", teacherId: "" }]); // Reset subjects
  };

  // Handle subject name change
  const handleSubjectChange = (index, event) => {
    const value = event.target.value;
    const updatedSubjects = [...subjects];
    updatedSubjects[index].subjectName = value;

    // If the user selects "Add New Subject", initialize with empty fields
    if (value === "add_new_subject") {
      updatedSubjects[index].isNew = true;
      updatedSubjects[index].subjectName = "";
    } else {
      updatedSubjects[index].isNew = false;
      updatedSubjects[index].action = updatedSubjects[index].action || "update"; // Default action
    }

    setSubjects(updatedSubjects);
  };

  // Handle teacher selection change
  const handleTeacherChange = (index, event) => {
    const value = event.target.value;
    const updatedSubjects = [...subjects];
    updatedSubjects[index].teacherId = value;
    setSubjects(updatedSubjects);
  };

  // Handle action selection change
  const handleActionChange = (index, event) => {
    const value = event.target.value;
    const updatedSubjects = [...subjects];
    updatedSubjects[index].action = value;

    // If action is not "add", ensure teacherId is set
    if (value !== "add") {
      updatedSubjects[index].teacherId = updatedSubjects[index].teacherId || "";
    }

    setSubjects(updatedSubjects);
  };

  // Add a new subject row
  const addMore = () => {
    setSubjects([...subjects, { subjectName: "", action: "", teacherId: "" }]);
  };

  // Remove a subject row
  const removeSubject = (index) => {
    const updatedSubjects = subjects.filter((_, i) => i !== index);
    setSubjects(updatedSubjects);
  };

  // Handle adding new subject from input
  const handleNewSubjectNameChange = (index, event) => {
    const value = event.target.value;
    const updatedSubjects = [...subjects];
    updatedSubjects[index].subjectName = value;
    updatedSubjects[index].action = "add"; // Set action to 'add' for new subjects
    setSubjects(updatedSubjects);
  };

  // Validate form before submission
  const validateForm = () => {
    let tempErrors = {};
    if (!selectedClass) tempErrors.selectedClass = "Class is required.";
    if (!selectedSection) tempErrors.selectedSection = "Section is required.";

    subjects.forEach((subject, index) => {
      if (!subject.subjectName) {
        tempErrors[`subjectName_${index}`] = "Subject name is required.";
      }
      if (subject.action === "update" && !subject.teacherId) {
        tempErrors[`teacherId_${index}`] = "Teacher is required for update.";
      }
      if (subject.isNew && !subject.subjectName.trim()) {
        tempErrors[`newSubjectName_${index}`] = "New subject name is required.";
      }
    });

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateForm()) {
      alert("Please fix the errors in the form.");
      return;
    }

    const newClassDetails = {
      className: selectedClass,
      section: selectedSection,
      subjects: subjects.map((subject) => {
        let subjectDetails = {
          subjectName: subject.subjectName,
          action: subject.action,
        };
        if (subject.action === "update" || subject.action === "add") {
          subjectDetails.teacherId = subject.teacherId;
        }
        return subjectDetails;
      }),
    };

    try {
      const response = await fetch(editClassUrl, {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(newClassDetails),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Failed to edit class.");
      }

      const result = await response.text();
      console.log(result);
      alert("Class updated successfully.");
      if (props.onSubmit) props.onSubmit(); // Ensure callback is invoked correctly
    } catch (error) {
      console.error("Error editing class:", error);
      alert(`Error editing class: ${error.message}`);
    }
  };

  return (
    <Box className="createNewClassSection" sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Edit Existing Class
      </Typography>
      <Grid container spacing={3}>
        {/* Class and Section Selection */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.selectedClass}>
            <InputLabel id="class-select-label">Select Class</InputLabel>
            <Select
              labelId="class-select-label"
              id="class-select"
              value={selectedClass}
              label="Select Class"
              onChange={handleClassChange}
            >
              {classes.map((cls, index) => (
                <MenuItem key={index} value={cls.className}>
                  {cls.className}
                </MenuItem>
              ))}
            </Select>
            {errors.selectedClass && (
              <Typography variant="caption" color="error">
                {errors.selectedClass}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.selectedSection}>
            <InputLabel id="section-select-label">Section</InputLabel>
            <Select
              labelId="section-select-label"
              id="section-select"
              value={selectedSection}
              label="Section"
              onChange={handleSectionChange}
              disabled={!selectedClass}
            >
              {selectedClass &&
                classes
                  .find((cls) => cls.className === selectedClass)
                  ?.sectionsAndSubjects.map((sAs, index) => (
                    <MenuItem key={index} value={sAs.section}>
                      {sAs.section}
                    </MenuItem>
                  ))}
            </Select>
            {errors.selectedSection && (
              <Typography variant="caption" color="error">
                {errors.selectedSection}
              </Typography>
            )}
          </FormControl>
        </Grid>

        {/* Subjects Section */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Subjects
          </Typography>
          <Grid container spacing={2}>
            {subjects.map((subject, index) => (
              <Grid container spacing={2} key={index} alignItems="center">
                {/* Subject Name Selection or Input */}
                <Grid item xs={12} md={4}>
                  <FormControl
                    fullWidth
                    error={!!errors[`subjectName_${index}`]}
                  >
                    <InputLabel id={`subject-select-label-${index}`}>
                      Subject
                    </InputLabel>
                    <Select
                      labelId={`subject-select-label-${index}`}
                      id={`subject-select-${index}`}
                      value={subject.subjectName || ""}
                      label="Subject"
                      onChange={(e) => handleSubjectChange(index, e)}
                    >
                      {selectedClass &&
                        selectedSection &&
                        classes
                          .find((cls) => cls.className === selectedClass)
                          ?.sectionsAndSubjects.find(
                            (sAs) => sAs.section === selectedSection
                          )
                          ?.subjects.map((subj, subIndex) => (
                            <MenuItem key={subIndex} value={subj.subjectName}>
                              {subj.subjectName}
                            </MenuItem>
                          ))}
                      <MenuItem value="add_new_subject">
                        <em>Add New Subject</em>
                      </MenuItem>
                    </Select>
                    {errors[`subjectName_${index}`] && (
                      <Typography variant="caption" color="error">
                        {errors[`subjectName_${index}`]}
                      </Typography>
                    )}
                  </FormControl>
                  {/* Render TextField for new subject name if "Add New Subject" is selected */}
                  {subject.isNew && (
                    <TextField
                      label="New Subject Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={subject.subjectName}
                      onChange={(e) => handleNewSubjectNameChange(index, e)}
                      error={!!errors[`newSubjectName_${index}`]}
                      helperText={errors[`newSubjectName_${index}`]}
                    />
                  )}
                </Grid>

                {/* Teacher Selection */}
                <Grid item xs={12} md={4}>
                  <FormControl
                    fullWidth
                    disabled={subject.action === "delete"}
                    error={!!errors[`teacherId_${index}`]}
                  >
                    <InputLabel id={`teacher-select-label-${index}`}>
                      Teacher
                    </InputLabel>
                    <Select
                      labelId={`teacher-select-label-${index}`}
                      id={`teacher-select-${index}`}
                      value={subject.teacherId || ""}
                      label="Teacher"
                      onChange={(e) => handleTeacherChange(index, e)}
                      required={subject.action !== "delete"}
                    >
                      {teachersList.map((teacher, tIndex) => (
                        <MenuItem key={tIndex} value={teacher.id}>
                          {teacher.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors[`teacherId_${index}`] && (
                      <Typography variant="caption" color="error">
                        {errors[`teacherId_${index}`]}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                {/* Action Selection */}
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id={`action-select-label-${index}`}>
                      Action
                    </InputLabel>
                    <Select
                      labelId={`action-select-label-${index}`}
                      id={`action-select-${index}`}
                      value={subject.action || ""}
                      label="Action"
                      onChange={(e) => handleActionChange(index, e)}
                    >
                      <MenuItem value="update">Update</MenuItem>
                      <MenuItem value="delete">Delete</MenuItem>
                      <MenuItem value="add">Add</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Remove Subject Button */}
                <Grid item xs={12} md={1}>
                  <IconButton
                    aria-label="remove subject"
                    onClick={() => removeSubject(index)}
                    disabled={subjects.length === 1}
                  >
                    <RemoveCircleOutlineIcon color="error" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>

          {/* Add More Subjects Button */}
          <Box sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              startIcon={<AddCircleOutlineIcon />}
              onClick={addMore}
            >
              Add More Subject
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Submit Button */}
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
        >
          Edit Class
        </Button>
      </Box>
    </Box>
  );
};

export default EditExistingClass;
